import React, { useMemo } from 'react';
import { useQuery } from 'react-apollo';
import { styled } from '@compiled/react';

import { N0 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

import { useSessionData } from '@confluence/session-data';

import { Preview } from './Preview';
import { TemplateBodiesQuery } from './TemplateBodiesQuery.graphql';
import {
	getBodyForTemplate,
	getTemplateIdsToFetch,
	getTemplateId,
	type TemplatePreviewTemplate,
} from './templatePreviewHelpers';
import { getDataProviders } from './PreviewRenderer/adfHelpers';
import type {
	TemplateBodiesQueryVariables,
	TemplateBodiesQuery as TemplateBodiesQueryResponse,
} from './__types__/TemplateBodiesQuery';

const DEFAULT_NUM_PREVIEWS_TO_FETCH_AT_ONCE = 30;
const PREVIEW_MIN_HEIGHT = 500;
const DEFAULT_MARGIN_BOTTOM = 30;

type Props = {
	spaceKey: string;
	templates: TemplatePreviewTemplate[];
	currentTemplateIndex?: number;
	numPreviewsToFetchAtOnce?: number;
	isScrollable?: boolean;
	marginBottom?: number;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Wrapper = styled.div<{ isScrollable: boolean }>({
	// See <TemplateContainer> note on background paradox
	background: token('elevation.surface.overlay', N0),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	height: (props) => (props.isScrollable ? '100%' : `${PREVIEW_MIN_HEIGHT}px`),
});

export const TemplatePreview = ({
	spaceKey,
	templates,
	currentTemplateIndex = -1,
	numPreviewsToFetchAtOnce = DEFAULT_NUM_PREVIEWS_TO_FETCH_AT_ONCE,
	isScrollable = false,
	marginBottom = DEFAULT_MARGIN_BOTTOM,
}: Props) => {
	const { cloudId, userId } = useSessionData();

	const ids = useMemo(
		() => getTemplateIdsToFetch(templates, currentTemplateIndex, numPreviewsToFetchAtOnce),
		[templates, currentTemplateIndex, numPreviewsToFetchAtOnce],
	);
	const { data, loading, error } = useQuery<
		TemplateBodiesQueryResponse,
		TemplateBodiesQueryVariables
	>(TemplateBodiesQuery, {
		variables: { spaceKey, ids },
		skip: !spaceKey || !templates.length || !ids.length,
	});

	const currentTemplate = templates[currentTemplateIndex];

	const currentTemplateId = currentTemplate ? getTemplateId(currentTemplate) : '';
	const fabricDataProviders = useMemo(
		() =>
			getDataProviders({
				cloudId,
				userId,
				templateIds: ids,
				spaceKey,
				currentTemplateId,
			}),
		[cloudId, userId, ids, spaceKey, currentTemplateId],
	);
	return (
		<Wrapper isScrollable={isScrollable}>
			<Preview
				templateBody={getBodyForTemplate(data, currentTemplate)}
				isLoading={loading}
				error={error}
				template={currentTemplate}
				isScrollable={isScrollable}
				marginBottom={marginBottom}
				fabricDataProviders={fabricDataProviders}
			/>
		</Wrapper>
	);
};
