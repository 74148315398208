import React from 'react';

import type {
	ExtensionParams,
	ExtensionHandler,
	Parameters,
} from '@atlaskit/editor-common/extensions';

import type { ExtensionHandlerProps } from '@confluence/fabric-extension-lib/entry-points/fabric-extension-lib-types';
import { LazyTableOfContentsLoader } from '@confluence/table-of-contents';
import { RENDERER } from '@confluence/macro-tracker';

import type { GetExtensionHandlersArgs } from './';

import { CreateFromTemplatePreviewButton } from './macros/CreateFromTemplatePreviewButton';

const context = {
	insertCss: (styles) => styles._insertCss(),
};

export const getMacroExtensionHandler = ({
	adf,
	cloudId,
	userId,
	contentId,
	spaceKey,
}: GetExtensionHandlersArgs): ExtensionHandler<Parameters> => {
	return (ext: ExtensionParams<Parameters>) => {
		const macroDefaultProps: ExtensionHandlerProps = {
			node: ext,
			extensionKey: ext.extensionKey,
			contentId: contentId || '',
			mode: RENDERER,
			context,
			spaceKey: spaceKey || '',
		};

		// some extensions can handle anon users, so change null userId to ""
		const extensionProps = {
			userId: userId || '',
			cloudId,
			adf,
			macroDefaultProps,
		};

		switch (ext.extensionKey) {
			case 'create-from-template':
				return <CreateFromTemplatePreviewButton {...ext} />;
			case 'toc':
				return <LazyTableOfContentsLoader {...extensionProps} />;
			default:
				return null;
		}
	};
};
