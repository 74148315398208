import gql from 'graphql-tag';

export const TemplateBodiesQuery = gql`
	query TemplateBodiesQuery($spaceKey: String!, $ids: [String!]!) {
		templateBodies(spaceKey: $spaceKey, ids: $ids) {
			nodes {
				id
				body {
					value
					representation
				}
			}
		}
	}
`;
