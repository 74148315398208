import React from 'react';
import { useIntl, defineMessages } from 'react-intl-next';

import type { ExtensionParams, Parameters } from '@atlaskit/editor-common/extensions';
import Button from '@atlaskit/button';

const i18n = defineMessages({
	createFromTemplateDefaultLabelText: {
		id: 'template-preview.inline-extension.create-from-template',
		defaultMessage: 'Create from Template',
		description:
			'Non-functional preview button that would allow a user to create a brand new templated page',
	},
});

export const CreateFromTemplatePreviewButton = (ext: ExtensionParams<Parameters>) => {
	const { buttonLabel, createButtonLabel } = ext.parameters?.macroParams;
	const intl = useIntl();

	return (
		<Button>
			{buttonLabel?.value ||
				createButtonLabel?.value ||
				intl.formatMessage(i18n.createFromTemplateDefaultLabelText)}
		</Button>
	);
};
