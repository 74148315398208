import React, { useContext } from 'react';
import { styled } from '@compiled/react';
import type { ApolloError } from 'apollo-client';

import { ReactRenderer } from '@atlaskit/renderer';
import type { ProviderFactory } from '@atlaskit/editor-common/provider-factory';
import { SmartCardProvider } from '@atlaskit/link-provider';

import {
	TEMPLATE_PREVIEW_EXPERIENCE,
	ExperienceTrackerContext,
} from '@confluence/experience-tracker';
import { useSessionData } from '@confluence/session-data';
import { getSmartCardRenderers } from '@confluence/fabric-extension-lib';
import { getMediaFeatureFlags } from '@confluence/fabric-media-support';
import { RendererAnalyticsListener } from '@confluence/fabric-analytics-listeners';
import { usePageContentId, usePageSpaceKey } from '@confluence/page-context';
import { useSmartCardProviderFeatureFlags } from '@confluence/linking-platform';

import { NoPreviewAvailable } from '../PreviewMessage';
import { PreviewAnalyticsContext } from '../PreviewAnalytics';
import type { TemplatePreviewTemplate } from '../templatePreviewHelpers';

import { getExtensionHandlers } from './extensions';

type StylingProps = {
	allowTableResizing?: boolean;
};

type Props = StylingProps & {
	template?: TemplatePreviewTemplate;
	previewString: string;
	fabricDataProviders?: ProviderFactory;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FixTableHorizontalScrollbars = styled.div<StylingProps>(
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	(props) =>
		!props.allowTableResizing &&
		`
  .pm-table-container {
    width: auto !important;
    left: auto !important;

    colgroup {
      display: none !important;
    }
  }
    `,
);

export const AdfPreviewRenderer = ({
	template,
	allowTableResizing = false,
	previewString,
	fabricDataProviders,
}: Props) => {
	const experienceTracker = useContext(ExperienceTrackerContext);
	const { featureFlags, cloudId, userId } = useSessionData();
	const linkingPlatformFeatureFlags = useSmartCardProviderFeatureFlags('renderer');

	const { fireAnalyticsEventWithTemplateData } = useContext(PreviewAnalyticsContext);
	const [contentId] = usePageContentId();
	const [spaceKey] = usePageSpaceKey();
	let adf;

	try {
		adf = JSON.parse(previewString);
	} catch (e) {
		fireAnalyticsEventWithTemplateData({
			type: 'sendTrackEvent',
			data: {
				source: 'templateDrawer',
				action: 'error',
				actionSubject: 'templatePreview',
				actionSubjectId: 'templatePreviewAdfParsingError',
				attributes: {
					errorMessage: e.message,
				},
			},
		});

		return <NoPreviewAvailable error={e} />;
	}

	const experienceSuccess = () =>
		experienceTracker.succeed({
			name: TEMPLATE_PREVIEW_EXPERIENCE,
		});

	const experienceFailure = (error: ApolloError | Error) =>
		experienceTracker.fail({
			name: TEMPLATE_PREVIEW_EXPERIENCE,
			error,
		});

	const extensionHandlers = getExtensionHandlers({
		adf,
		cloudId,
		userId,
		contentId,
		spaceKey,
		template,
	});

	return (
		<FixTableHorizontalScrollbars allowTableResizing={allowTableResizing}>
			<SmartCardProvider {...getSmartCardRenderers()} featureFlags={linkingPlatformFeatureFlags}>
				<RendererAnalyticsListener featureFlags={featureFlags} source="templatePreview">
					<ReactRenderer
						adfStage="stage0"
						document={adf}
						appearance="full-page"
						allowColumnSorting={false}
						enableSsrInlineScripts={false}
						allowHeadingAnchorLinks={false}
						stickyHeaders={undefined}
						dataProviders={fabricDataProviders}
						extensionHandlers={extensionHandlers}
						key={previewString}
						onComplete={experienceSuccess}
						onError={experienceFailure}
						allowPlaceholderText
						media={{
							featureFlags: getMediaFeatureFlags(featureFlags),
						}}
					/>
				</RendererAnalyticsListener>
			</SmartCardProvider>
		</FixTableHorizontalScrollbars>
	);
};
