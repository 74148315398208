import React, { useContext } from 'react';
import { styled } from '@compiled/react';
import type { ApolloError } from 'apollo-client';

import Spinner from '@atlaskit/spinner/spinner';
import type { ProviderFactory } from '@atlaskit/editor-common/provider-factory';

import {
	TEMPLATE_PREVIEW_EXPERIENCE,
	stopExperienceOnError,
	ExperienceTrackerContext,
} from '@confluence/experience-tracker';
import { ErrorDisplay, isNotFoundError, isUnauthorizedError } from '@confluence/error-boundary';

import { hasCustomPreview, type TemplatePreviewTemplate } from './templatePreviewHelpers';
import { PreviewRenderer } from './PreviewRenderer';
import { LoadingError } from './PreviewMessage';
import type { TemplateBodiesQuery_templateBodies_nodes as TemplateBody } from './__types__/TemplateBodiesQuery';

type PreviewProps = {
	templateBody?: TemplateBody;
	isLoading?: boolean;
	error?: ApolloError;
	template: TemplatePreviewTemplate;
	isScrollable: boolean;
	marginBottom: number;
	fabricDataProviders: ProviderFactory;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CenteredContainer = styled.div({
	height: '100%',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
});

export const Preview = ({
	templateBody,
	isLoading,
	error,
	template,
	isScrollable,
	marginBottom,
	fabricDataProviders,
}: PreviewProps) => {
	const experienceTracker = useContext(ExperienceTrackerContext);

	if (isLoading && !hasCustomPreview(template)) {
		return (
			<CenteredContainer>
				<Spinner size="medium" testId="template-preview-spinner" />
			</CenteredContainer>
		);
	}
	if (error && !hasCustomPreview(template)) {
		if (isUnauthorizedError(error) || isNotFoundError(error)) {
			experienceTracker.succeed({
				name: TEMPLATE_PREVIEW_EXPERIENCE,
			});
		} else {
			stopExperienceOnError(TEMPLATE_PREVIEW_EXPERIENCE, error);
		}

		return (
			<ErrorDisplay error={error}>
				<LoadingError error={error} />
			</ErrorDisplay>
		);
	}

	return (
		<PreviewRenderer
			templateBody={templateBody}
			error={error}
			template={template}
			isScrollable={isScrollable}
			marginBottom={marginBottom}
			fabricDataProviders={fabricDataProviders}
		/>
	);
};
