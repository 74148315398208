import React, { useContext } from 'react';
import type { IntlShape } from 'react-intl-next';
import { defineMessages, injectIntl, FormattedMessage } from 'react-intl-next';

import {
	TEMPLATE_PREVIEW_EXPERIENCE,
	ExperienceTrackerContext,
} from '@confluence/experience-tracker';

import { PreviewMessage } from './PreviewMessage';
import blogPostIcon from './img/blogPostIcon.svg';

export const i18n = defineMessages({
	title: {
		id: 'template-preview.custom.blog-post.title',
		defaultMessage: 'Bring your blog to life',
		description: 'Title for blog post preview',
	},
	message: {
		id: 'template-preview.custom.blog-post.message',
		defaultMessage:
			'You control what goes in a blog post, so {lineBreak} let your imagination run wild.',
		description: 'Message for blog post preview',
	},
	iconDescription: {
		id: 'template-preview.custom.blog-post.icon.description',
		defaultMessage: 'Blog post',
		description: 'Icon description for blog post preview',
	},
});

type Props = {
	intl: IntlShape;
};

export const BLOG_POST_ITEM_MODULE_COMPLETE_KEY =
	'com.atlassian.confluence.plugins.confluence-create-content-plugin:create-blog-post';

export const CustomBlogPostPreview = injectIntl(({ intl }: Props) => {
	const experienceTracker = useContext(ExperienceTrackerContext);
	// We're not using <ExperienceSuccess /> because this component isn't actually de-mounting properly, the current logic just hides it.
	experienceTracker.succeed({
		name: TEMPLATE_PREVIEW_EXPERIENCE,
	});

	return (
		<PreviewMessage
			title={intl.formatMessage(i18n.title)}
			message={<FormattedMessage {...i18n.message} values={{ lineBreak: <br /> }} />}
			icon={blogPostIcon}
			iconDescription={intl.formatMessage(i18n.iconDescription)}
			iconSize="medium"
			testId="template-preview-custom-blog-post-preview"
		/>
	);
});
