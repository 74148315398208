import type { ReactNode } from 'react';
import React, { createContext, useMemo } from 'react';

import type { AnalyticsEventPayload } from '@atlaskit/analytics-next';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { getTemplateIdAttributesForAnalytics } from '@confluence/template-utils';

import type { TemplatePreviewTemplate } from '../templatePreviewHelpers';

const PreviewAnalyticsContextDefaultValue = {
	fireAnalyticsEvent: (_payload: AnalyticsEventPayload) => {},
	fireAnalyticsEventWithTemplateData: (_payload: AnalyticsEventPayload) => {},
};

export const PreviewAnalyticsContext = createContext(PreviewAnalyticsContextDefaultValue);

type Props = {
	template?: TemplatePreviewTemplate;
	children: ReactNode;
};

export const PreviewAnalyticsProvider = ({ template, children }: Props) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const contextValue = useMemo(
		() => ({
			fireAnalyticsEvent: (payload: AnalyticsEventPayload) => {
				createAnalyticsEvent({
					...payload,
					data: {
						...payload.data,
						attributes: {
							...payload.data.attributes,
						},
					},
				}).fire();
			},
			fireAnalyticsEventWithTemplateData: (payload: AnalyticsEventPayload) => {
				if (!template) {
					return;
				}
				createAnalyticsEvent({
					...payload,
					data: {
						...payload.data,
						attributes: {
							...getTemplateIdAttributesForAnalytics(template),
							...payload.data.attributes,
						},
					},
				}).fire();
			},
		}),
		[createAnalyticsEvent, template],
	);

	return (
		<PreviewAnalyticsContext.Provider value={contextValue}>
			{children}
		</PreviewAnalyticsContext.Provider>
	);
};
