import React, { useContext } from 'react';
import { styled } from '@compiled/react';

import { token } from '@atlaskit/tokens';

import {
	TEMPLATE_PREVIEW_EXPERIENCE,
	ExperienceTrackerContext,
} from '@confluence/experience-tracker';

type Props = {
	previewString: string;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Wrapper = styled.div({
	padding: `0 ${token('space.400', '32px')}`,
});

export const TinyPreviewRenderer = ({ previewString }: Props) => {
	const experienceTracker = useContext(ExperienceTrackerContext);
	// We're not using <ExperienceSuccess /> because this component isn't actually de-mounting properly, the current logic just hides it.
	experienceTracker.succeed({
		name: TEMPLATE_PREVIEW_EXPERIENCE,
	});

	return <Wrapper dangerouslySetInnerHTML={{ __html: previewString }} />;
};
