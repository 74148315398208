import type { ExtensionHandlers } from '@atlaskit/editor-common/extensions';
import type { ADDoc } from '@atlaskit/editor-common/validator';

import { getRendererSmartButtonExtensionHandlers } from '@confluence/automation-extensions';

import type { TemplatePreviewTemplate } from '../../templatePreviewHelpers';

import { getMacroExtensionHandler } from './macroExtensionHandler';
import { getTemplateExtensionHandler } from './templateExtensionHandler';

export type GetExtensionHandlersArgs = {
	adf: ADDoc;
	cloudId: string;
	userId: string | null;
	contentId?: string;
	spaceKey?: string;
	template?: TemplatePreviewTemplate;
};

export const getExtensionHandlers = (
	extensionHandlerArgs: GetExtensionHandlersArgs,
): ExtensionHandlers => {
	return {
		'com.atlassian.confluence.macro.core': getMacroExtensionHandler(extensionHandlerArgs),
		'com.atlassian.confluence.template': getTemplateExtensionHandler(extensionHandlerArgs),
		'com.atlassian.automation.smart-button': getRendererSmartButtonExtensionHandlers({
			contentId: extensionHandlerArgs.contentId,
			contentType: 'page',
			cloudId: extensionHandlerArgs.cloudId,
		})['com.atlassian.automation.smart-button'],
	};
};
