import React, { useContext, useEffect } from 'react';
import type { IntlShape } from 'react-intl-next';
import { defineMessages, injectIntl, FormattedMessage } from 'react-intl-next';
import type { ApolloError } from 'apollo-client';

import { PreviewAnalyticsContext } from '../PreviewAnalytics';

import { PreviewMessage } from './PreviewMessage';
import loadingErrorIcon from './img/loadingErrorIcon.svg';

export const i18n = defineMessages({
	title: {
		id: 'template-preview.errors.loading.title',
		defaultMessage: 'We couldn’t load the preview',
		description: 'Error title for when preview failed to load',
	},
	message: {
		id: 'template-preview.errors.loading.message',
		defaultMessage:
			// TODO: replace straight quotes with curly quotes (see go/curlyquotes)
			// eslint-disable-next-line no-restricted-syntax
			"We're having trouble loading the preview right now. {lineBreak} Refresh the page to try again.",
		description: 'Error message for when preview failed to load',
	},
	iconDescription: {
		id: 'template-preview.errors.loading.icon.description',
		defaultMessage: 'Can’t display the preview',
		description: 'Error icon description for when preview failed to load',
	},
});

type Props = {
	intl: IntlShape;
	error: ApolloError;
};

export const LoadingError = injectIntl(({ intl, error }: Props) => {
	const { fireAnalyticsEvent } = useContext(PreviewAnalyticsContext);

	useEffect(() => {
		fireAnalyticsEvent({
			type: 'sendTrackEvent',
			data: {
				source: 'templateDrawer',
				action: 'error',
				actionSubject: 'templatePreview',
				actionSubjectId: 'templatePreviewFetchBodiesError',
				attributes: {
					errorMessage: error.message,
				},
			},
		});
	}, [error, fireAnalyticsEvent]);

	return (
		<PreviewMessage
			title={intl.formatMessage(i18n.title)}
			message={<FormattedMessage {...i18n.message} values={{ lineBreak: <br /> }} />}
			icon={loadingErrorIcon}
			iconDescription={intl.formatMessage(i18n.iconDescription)}
			testId="template-preview-loading-error"
		/>
	);
});
