import type { ReactElement } from 'react';
import React from 'react';
import { styled } from '@compiled/react';

import { N800 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CenteredContainer = styled.div({
	height: '100%',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Wrapper = styled.div({
	textAlign: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Icon = styled.img<{ size: number }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	height: `${(props) => props.size}px`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Title = styled.h2({
	font: token('font.heading.medium'),
	margin: `${token('space.600', '48px')} 0 0 0`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Message = styled.p({
	font: token('font.body'),
	color: token('color.text', N800),
	margin: `${token('space.300', '24px')} 0 0 0`,
});

type Props = {
	title: string;
	message: string | ReactElement;
	icon: string;
	iconDescription: string;
	iconSize?: 'small' | 'medium';
	testId?: string;
};

export const PreviewMessage = ({
	title,
	message,
	icon,
	iconDescription,
	testId,
	iconSize = 'small',
}: Props) => (
	<CenteredContainer data-testid={testId}>
		<Wrapper>
			<Icon src={icon} alt={iconDescription} size={iconSize === 'small' ? 135 : 240} />
			<Title>{title}</Title>
			<Message>{message}</Message>
		</Wrapper>
	</CenteredContainer>
);
