import React from 'react';
import type { WrappedComponentProps } from 'react-intl-next';
import { injectIntl, defineMessages } from 'react-intl-next';

import { Date as DateComponent } from '@atlaskit/date';
import type {
	ExtensionParams,
	ExtensionHandler,
	Parameters,
} from '@atlaskit/editor-common/extensions';

import type { TemplateVariableParams } from '@confluence/template-variable';
import { TemplateVariableInput } from '@confluence/template-variable';
import type { SessionDataType } from '@confluence/session-data';
import { SessionData } from '@confluence/session-data';
import { getUserTimezoneDate } from '@confluence/template-utils';

import type { GetExtensionHandlersArgs } from './index';

export const preConfiguredBlueprintVariablesI18n = defineMessages({
	documentOwner: {
		id: 'template-preview.custom-blueprint-variable-handler.document-owner',
		defaultMessage: 'Document owner',
		description: 'Text shown in a template preview to indicate who the owner of the document is.',
	},
});

type Props = {
	ext: ExtensionParams<Parameters>;
	isTemplate?: boolean;
} & WrappedComponentProps;

export const getTemplateExtensionHandler = ({
	template,
}: GetExtensionHandlersArgs): ExtensionHandler<Parameters> => {
	return (ext: ExtensionParams<Parameters>) => {
		if (ext.extensionKey === 'variable') {
			const { type, name, params } = ext.parameters as TemplateVariableParams;
			const isTemplate = template?.templateId;

			switch (name) {
				case 'currentDateLozenge':
				case 'createdDate':
					return (
						<SessionData>
							{({ userId, isLicensed, timeZone }: SessionDataType) => {
								const currentDate = getUserTimezoneDate({
									userId,
									isLicensed,
									timeZone,
									dateFormat: 'yyyy/MM/dd',
								});

								return <DateComponent value={Date.parse(currentDate)} format="MMM d, yyyy" />;
							}}
						</SessionData>
					);
				default:
					return isTemplate ? (
						<TemplateVariableInput parameters={{ name, type, params, isDisabled: true }} />
					) : (
						<BlueprintVariablePreview ext={ext} />
					);
			}
		} else {
			return <DefaultExtensionHandlerComponent />;
		}
	};
};

const BlueprintVariablePreview = injectIntl(({ ext, intl }: Props) => {
	const { type, name, params } = ext.parameters as TemplateVariableParams;
	const userFriendlyVariableNameI18n = preConfiguredBlueprintVariablesI18n[name];

	return userFriendlyVariableNameI18n ? (
		<TemplateVariableInput
			parameters={{
				name: intl.formatMessage(userFriendlyVariableNameI18n),
				type,
				params,
				isDisabled: true,
			}}
		/>
	) : (
		<DefaultExtensionHandlerComponent />
	);
});

const DefaultExtensionHandlerComponent = () => <span data-testid="other-extension" />;
