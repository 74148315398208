import React, { Fragment } from 'react';
import type { IntlShape } from 'react-intl-next';
import { defineMessages, injectIntl, FormattedMessage } from 'react-intl-next';
import type { ApolloError } from 'apollo-client';

import {
	TEMPLATE_PREVIEW_EXPERIENCE,
	ExperienceFailure,
	ExperienceSuccess,
} from '@confluence/experience-tracker';
import { ErrorDisplay } from '@confluence/error-boundary';

import { PreviewMessage } from './PreviewMessage';
import displayErrorIcon from './img/displayErrorIcon.svg';

export const i18n = defineMessages({
	title: {
		id: 'template-preview.errors.display.title',
		defaultMessage: 'Nothing to see until you add it',
		description: 'Error title for when template does not have preview',
	},
	message: {
		id: 'template-preview.errors.display.message',
		defaultMessage:
			'There’s no preview available for this template {lineBreak} because it’s tailored to details you provide.',
		description: 'Error message for when template does not have preview',
	},
	iconDescription: {
		id: 'template-preview.errors.display.icon.description',
		defaultMessage: 'Can’t display the preview',
		description: 'Error icon description for when template does not have preview',
	},
});

type Props = {
	intl: IntlShape;
	error?: ApolloError | Error;
};

export const NoPreviewAvailable = injectIntl(({ intl, error }: Props) => {
	const handleExperiences = error ? (
		<ErrorDisplay error={error}>
			<ExperienceFailure error={error} name={TEMPLATE_PREVIEW_EXPERIENCE} />
		</ErrorDisplay>
	) : (
		<ExperienceSuccess name={TEMPLATE_PREVIEW_EXPERIENCE} />
	);

	return (
		<Fragment>
			<PreviewMessage
				title={intl.formatMessage(i18n.title)}
				message={<FormattedMessage {...i18n.message} values={{ lineBreak: <br /> }} />}
				icon={displayErrorIcon}
				iconDescription={intl.formatMessage(i18n.iconDescription)}
				testId="template-no-preview-available"
			/>
			{handleExperiences}
		</Fragment>
	);
});
