import { EmojiResource } from '@atlaskit/emoji/resource';
import { ProviderFactory } from '@atlaskit/editor-common/provider-factory';

import { getEmojiProvider } from '@confluence/fabric-providers';
import { createRendererTemplateMediaProvider } from '@confluence/fabric-media-support';

interface GetDataProvidersOptions {
	cloudId: string;
	userId: string | null;
	templateIds: string[];
	spaceKey: string;
	currentTemplateId: string;
}

export const getDataProviders = (options: GetDataProvidersOptions): ProviderFactory => {
	const { cloudId, userId, templateIds, spaceKey, currentTemplateId } = options;
	const dataProviders = ProviderFactory.create({});
	dataProviders.setProvider('emojiProvider', getEmojiProvider(EmojiResource, { cloudId, userId }));

	const contextIdentifierProvider = () => {
		const objectId = JSON.stringify({
			templateId: currentTemplateId,
			spaceKey,
		});
		return Promise.resolve({
			containerId: spaceKey,
			objectId,
			product: 'confluence',
		});
	};

	dataProviders.setProvider('contextIdentifierProvider', contextIdentifierProvider());

	dataProviders.setProvider(
		'mediaProvider',
		createRendererTemplateMediaProvider({
			spaceKey,
			templateIds,
		}),
	);

	return dataProviders;
};
